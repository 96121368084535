import * as React from 'react';
import { ScrollView, StyleSheet } from 'react-native';


import { Text, View } from '../components/Themed';
// import FormingCheckbox from '../forming/components/FormingCheckbox';
import Forming from '../forming/components/Forming';

import userService from '../services/userService';
import storageService from '../services/storageService';
import UserForm from '../components/UserForm';

export default function TabOneScreen(): JSX.Element | null {
  // Gigantic hack to set top-level scrolling state
  const [ scrolling, setScrolling ] = React.useState<boolean>(true);

  const scrollable = (value: boolean) => {
    setScrolling(value);
  }

  //React.useEffect(() => {
  //  console.log('*****useEffect ran');
  //});

  // Login related
  const [ok, setOk] = React.useState(false);

  // set the username if it's stored internally
  async function setUsername(): Promise<void> {
    const username = await storageService.readValue('@username');
    if (username) {
      userService.setUser(username);
      if (userService.getClientKey()) {
        // We have the client key. Use it and don't ask for the username
        setOk(true);
      }
    }
  }

  function handleChange(): void {
    // Re-render the UI. Issued from child
    //console.log('handleChange', userService.getClientKey());
    console.log('setting ok to true');
    setOk(true);
    console.log(ok);
  }

  // Get the username from storage and fetch the key. If missing, make them enter the username
  const prom = setUsername();
  prom.then(() => {
    // Read is complete
    console.log('username check');
  });

  if (!userService.getClientKey()) {
    // Cannot continue without a clientkey. Show the UserForm
    return (
      <UserForm handleChange={handleChange}/>
    )
  }

  console.log('Rendering tabone');
  return (
    <ScrollView scrollEnabled={scrolling}>
      <View style={styles.container}>
        <View style={{flexDirection: 'row'}}>
          <Text style={styles.title}>Dynamic Form POC</Text>
        </View>
        <Forming scrollable={scrollable} />
      </View>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
