import * as React from 'react';
import { SafeAreaView, StyleSheet } from 'react-native';
import { TextInput, Button } from 'react-native-paper';

// import MonacoEditor from 'react-monaco-editor';
import { ControlledEditor as MonacoEditor } from "@monaco-editor/react";

import axiosService from '../services/axiosService';
import userService from '../services/userService';

import { Text, View } from '../components/Themed';
import DropDown from 'react-native-paper-dropdown';

import { showMessage } from "react-native-flash-message";


const template = `
{
  "version": "1",
  "type": "collection",
  "components": [
    {"type": "label", "size": "h3", "label": "Simple Form"}
  ]
}`;

export default class TabTwoScreen extends React.Component {
  constructor(props: unknown) {
    super(props);

    this.editorGetter = null;

    const canedit:boolean = userService.getCanEdit();

    this.state = {
      canedit,
      currentForm: '',
      saveas: '',
      code: template
    };
  }

  async componentDidMount(): void {
    // Before using the editor, make sure the current user is allowed access to the forms
    console.log('checking forms');
    const forms = await axiosService.get('/api/form');
    console.log('forms', forms);
    let formlist:Array<string> = [];
    if (forms.status == 500) {
      // server is down
      console.error("SERVER is down");
    }
    else if (forms && forms.data) {
      formlist = forms.data;
    }
    this.setState({formlist});
  }

  async setForm(name: string): void {
    this.setState({currentForm: name, saveas: name});
    const form = await axiosService.get(`/api/form/${name}`);
    console.log('received', form);
    if (!form || !form.data || Object.keys(form.data).length === 0) {
      // Invalid form
      console.log('Invalid Form');
    }
    else {
      console.log(form);
      const data = JSON.stringify(form.data, 0, 2);
      this.setState({code: data});
      
    }
  }

  async saveForm(): void {
    if (!this.state.saveas) {
      showMessage({
        message: 'The form name is missing',
        type: 'warning'
      });

      console.error('No Name assigned');
      return;
    }
    const name = `/api/form/${this.state.saveas}`;
    const json = this.editorGetter();

    try {
      JSON.parse(json);
      await axiosService.put(name, json);
    }
    catch (ex) {
      showMessage({
        message: 'Invalid JSON',
        type: 'warning'
      });
      // Not valid json. Do not write
      console.error('Invalid Json');
    }
    //console.log('json', json);
 }

  renderDropdown(): JSX.Element | null {
    const formlist = this.state.formlist || [];
    const formitems = formlist.map(item => { return {label: item, value: item}});
     return (
      <SafeAreaView style={styles.containerStyle}>
        <DropDown
          label={'Form'}
          mode={'outlined'}
          value={this.state.currentForm}
          setValue={async (x: string) => this.setForm(x)}
          list={formitems}
          visible={this.state.showDropdown}
          showDropDown={() => this.setState({showDropdown: true})}
          onDismiss={() => this.setState({showDropdown: false})}
          inputProps={{
            right: <TextInput.Icon name={'menu-down'} />,
          }}
          />
      </SafeAreaView>
    )
  }
 

  renderSaveAs(): JSZ.Element | null {
    return (
      <View style={styles.container}>
        <TextInput 
          label='Save As' 
          value={this.state.saveas}
          onChangeText = {text => this.setState({saveas: text})}
        />
      <Button
        onPress={async () => {
          await this.saveForm();
          //this.setState({checked: !this.state.checked})
        }}>Save
      </Button>
      </View>
    )
  }

  // Called if the user doesn't have permissions to edit a document
  renderNothing(): JSX.Element | null {
    return (
      <View/>
    )
  }

  render(): JSX.Element | null {
    const dropdown = this.renderDropdown();
    const saveas = this.renderSaveAs();
    
    if (!this.state.canedit) {
      return this.renderNothing();
    }

    
    const self = this; // eslint-disable-line @typescript-eslint/no-this-alias

    function handleEditorDidMount(_valueGetter): void {
      console.log('handleEditorDidMount', _valueGetter);
      if (_valueGetter) {
        self.editorGetter = _valueGetter;
      }
    }

    return (
      <View style={styles.container}>
        {dropdown}
        {saveas}
        <Text style={styles.title}>Form Editor</Text>
        <MonacoEditor
          width="100%"
          height="75%"
          language="json"
          value={this.state.code}
          options={{
            selectOnLineNumbers: true
          }}
          editorDidMount={handleEditorDidMount}
        />
      </View>
    );
  }

  //render(): JSX.Element | null {
  //  const dropdown = this.renderDropdown();
  //  return (
  //    <View>
  //      {dropdown}
  //    </View>
  //  )
  //}

}

/*
export default function TabTwoScreenZZ(): JSX.Element | null {
  const template = `
  {
    "version": "1",
    "type": "collection",
    "components": [
      {"type": "label", "size": "h3", "label": "Simple Form"}
    ]
  }`;

  return (
    <View style={styles.container}>
      <FormList/>
      <Text style={styles.title}>Form Editor</Text>
      <MonacoEditor
        width="100%"
        language="json"
        value={template}
        options={{
          selectOnLineNumbers: true
        }}
      />
    </View>
  );
}
*/

const styles = StyleSheet.create({
  containerStyle: {
    flex: 1,
    marginHorizontal: 20,
    justifyContent: 'center',
    width: '50%'
  },
  container: {
    flex: 1,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  separator: {
    marginVertical: 30,
    height: 1,
    width: '80%',
  },
});
