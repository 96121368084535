import * as React from 'react';
import { StyleSheet, View } from 'react-native';

import userService from '../services/userService';

export default function LogoutScreen(): JSX.Element | null  {
  // Logout the user

  // Does what comonentWillMount does
  // A bit strange looking. I got this syntax here:
  // https://medium.com/javascript-in-plain-english/how-to-use-async-function-in-react-hook-useeffect-typescript-js-6204a788a435
  React.useEffect(() => {
    async function logout() {
      console.log('Calling logout');
      await userService.logout();
    }

    logout();
  }, [])

  return (
    <View style={styles.container}>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
  },
  title: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  link: {
    marginTop: 15,
    paddingVertical: 15,
  },
  linkText: {
    fontSize: 14,
    color: '#2e78b7',
  },
});