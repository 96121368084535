import { NavigationContainer, DefaultTheme, DarkTheme } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import * as React from 'react';
import { ColorSchemeName } from 'react-native';

import NotFoundScreen from '../screens/NotFoundScreen';
import LoginScreen from '../screens/LoginScreen';
import LogoutScreen from '../screens/LogoutScreen';
import { RootStackParamList } from '../types';
import BottomTabNavigator from './BottomTabNavigator';
import LinkingConfiguration from './LinkingConfiguration';

// If you are not familiar with React Navigation, we recommend going through the
// "Fundamentals" guide: https://reactnavigation.org/docs/getting-started
export default function Navigation({ colorScheme }: { colorScheme: ColorSchemeName }): JSX.Element | null  {
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}>
      <RootNavigator />
    </NavigationContainer>
  );
}

// A root stack navigator is often used for displaying modals on top of all other content
// Read more here: https://reactnavigation.org/docs/modal
const Stack = createStackNavigator<RootStackParamList>();


function RootNavigator() {
  /*
  const [ok, setOk] = React.useState(false);

  // set the username if it's stored internally
  async function setUsername(): Promise<void> {
    const username = await storageService.readValue('@username');
    if (username) {
      userService.setUser(username);
      if (userService.getClientKey()) {
        // We have the client key. Use it and don't ask for the username
        setOk(true);
      }
    }
  }

  function handleChange(): void {
    // Re-render the UI. Issued from child
    //console.log('handleChange', userService.getClientKey());
    setOk(true);
  }

  // Get the username from storage and fetch the key. If missing, make them enter the username
  const prom = setUsername();
  prom.then(() => {
    // Read is complete
    console.log('username check');
  });

  if (!userService.getClientKey()) {
    // Cannot continue without a clientkey. Show the UserForm
    return (
      <UserForm handleChange={handleChange}/>
    )
  }
*/
  return (
    <Stack.Navigator /*initialRouteName='Login'*/ screenOptions={{ headerShown: false }}>
      <Stack.Screen name="Root" component={BottomTabNavigator} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={{ title: 'Oops!' }} />
      <Stack.Screen name="Login" component={LoginScreen} />
      <Stack.Screen name="Logout" component={LogoutScreen} />
    </Stack.Navigator>
  );
}
